
 .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}


.login-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;

}


.gray-bg {
  background: url('./assets/bgadmin.jpg') no-repeat top center;
  background-color: #f3f3f4;
}

.badge{
	display:inline-block;
	min-width:10px;
	padding:5px 9px;
	font-size:12px;
	font-weight:700;
	color:#fff;
	line-height:1;
	vertical-align:baseline;
	white-space:nowrap;
	text-align:center;
	background-color:#999;
	border-radius:10px;
  margin-right:5px
}

.badge-info{
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity));
  color:#fff
}
.badge-info:hover{
  --tw-bg-opacity: 1;
    background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}
.badge-danger{
  --tw-bg-opacity: 1;
    background-color: rgb(220 38 38 / var(--tw-bg-opacity));
  color:#fff
}
.badge-danger:hover{
  --tw-bg-opacity: 1;
    background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

table {
  font-size: 100%;
  border: 1px solid #CCC;
  border-collapse: collapse;
  margin: 0;
  width: 100%;
  clear: both;
}

caption {
  padding: 6px 4px;
  text-align: left;
  color: #666;
  font-size: 115%;
  border-top: 3px solid #999;
  border-left: 3px solid #999;
  border-right: 3px solid #999;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #E8E8E8;
}

table th {
  color: #555;
  font-weight: bold;
  border-bottom: 1px solid #CCC;
  padding: 7px;
  text-align: left;
  text-transform: uppercase;
  background-color: #D8D8D8;
}

table th.last {
  border-right: none;
  border-bottom: 1px solid #CCC;
}

table td {
  margin: 0;
  padding: 4px 6px;
  text-align: left;
  vertical-align: top;
}

table tr.odd-row {
  background-color: #f0f0f0;
}

table td.form-field-label {
  text-align: right;
  font-weight: bold;
  width: 33%;
  vertical-align: top;
}

table td.submit-row {
  background-color: #F3EFEF;
  text-align: right;
}

table a.action {
  border-style: none;
  background-color: transparent;
}
/* .dataTables_filter,.dataTables_length, .dataTables_info { display: none; } */
.dataTables_filter,.dataTables_paginate{ display: none;}

.ibox-title{
    display: block;
    float: none;
    margin-top: 0;
    position: relative;
    padding: 1rem 1rem;
    text-align: left;
    background-color: #2f4050;
    color: white;
    
}

.ibox{
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 8px;
  color: #676a6c;  
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #2f4050;
}
.ibox::after::before{
  display: table;
}
.ibox-content1, .ibox-content2 {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}


 